import { useI18nStore } from '@/stores/i18nStore'
import { useDynamicContentStore } from '@/stores/dynamicContent'
export default {
  methods: {
    async getDynamicContent({ contents = [], getAll = false }) {
      const i18nStore = useI18nStore()
      const dynamicContentStore = useDynamicContentStore()
      let locale = i18nStore.langCode(true)
      console.log('getDynamicContent', locale)
      let toUpdateContents = null
      if (contents?.length) {
        toUpdateContents = dynamicContentStore.contentNeedsUpdate({
          contents,
          locale,
        })
      }
      let fullContentStored = dynamicContentStore.getFullContentStored
      if ((getAll && !fullContentStored) || toUpdateContents?.length) {
        // console.log(
        //   getAll ? `Get all content for ${contents?.join(', ')}` : `Needs dynamicContent - ${toUpdateContents}`
        // )
        await dynamicContentStore.fetchDynamicContent({
          content: getAll ? contents : toUpdateContents,
          locale,
          getAll,
        })
      }
    },
  },
}
