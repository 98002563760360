<template>
  <footer class="club">
    <div class="footer-container">
      <div class="inner-container fad">
        <div class="footer-cols">
          <!-- <div class="footer-col">
            <NuxtLinkLocale :to="'cgv'" :title="$t('pages.cgv')">
              {{ $t('pages.cgv') }}
            </NuxtLinkLocale>
          </div> -->
          <div class="footer-col" data-test-id="footer-cgu">
            <NuxtLinkLocale :to="'cgu'" :title="$t('pages.cgu')">
              {{ $t('pages.cgu') }}
            </NuxtLinkLocale>
          </div>
          <div class="footer-col" data-test-id="footer-privacy">
            <NuxtLinkLocale :to="'privacy'" :title="$t('pages.privacy')">
              {{ $t('pages.privacy') }}
            </NuxtLinkLocale>
          </div>
          <div class="footer-col" data-test-id="footer-cookies">
            <a href="javascript:openAxeptioCookies()">
              {{ $t('footer.cookies') }}
            </a>
          </div>
          <div class="footer-col" data-test-id="footer-contact">
            <a href="https://www.pamline.fr/contacts-implantations" target="_blank" :title="$t('pages.contact')">
              {{ $t('pages.contact') }}
            </a>
          </div>
          <!-- <div class="footer-col">
            <NuxtLinkLocale :to="'mission'" :title="$t('pages.about')">
              {{ $t('pages.about') }}
            </NuxtLinkLocale>
          </div> -->
          <!-- <div class="footer-col">
            <a href="https://stock-prohelp.zendesk.com/hc/fr" :title="$t('pages.faq')" target="_blank">
              {{ $t('pages.faq') }}
            </a>
          </div> -->
        </div>
        <div class="footer-cols footer-cols-1-4">
          <div class="footer-col footer-logo">
            <ImageLazy
              v-if="clubConfig?.components?.footer?.logo || currentClub?.logo_url"
              :src="clubConfig?.components?.footer?.logo || currentClub.logo_url"
              :alt="`${currentClub.name}-logo`"
              :title="`${currentClub.name}-logo`"
              data-test-id="footer-logo-pam"
            />
            <div class="copyright-tag" data-test-id="copyright-tag">
              <span>Club powered by</span>
              <img src="~/assets/logos/logo-stockpro-color.svg" alt="Logo StockPro" title="Logo StockPro" />
            </div>
          </div>
          <div v-if="clubConfig?.components?.footer" class="footer-col footer-about">
            <div class="footer-flex-title" data-test-id="footer-flex-title">
              <div class="title u-c-dark-blue">
                {{ clubConfig.components.footer.title }}
              </div>
              <div class="footer-socials">
                <a
                  v-if="clubSocial('facebook')"
                  :href="`https://www.facebook.com/${clubSocial('facebook').link}/`"
                  :title="$t('footer.socialFacebook')"
                  target="_blank"
                  class="facebook"
                >
                  <div class="icon-social-footer-facebook u-bg-social-icons" />
                </a>
                <a
                  v-if="clubSocial('instagram')"
                  :href="`https://www.instagram.com/${clubSocial('instagram').link}/`"
                  :title="$t('footer.socialInstagram')"
                  target="_blank"
                  class="instagram"
                >
                  <div class="icon-social-footer-instagram u-bg-social-icons" />
                </a>
                <a
                  v-if="clubSocial('linkedin')"
                  :href="`https://www.linkedin.com/company/${clubSocial('linkedin').link}/`"
                  :title="$t('footer.socialLinkedIn')"
                  target="_blank"
                  class="linkedin"
                  data-test-id="footer-socialLinkedIn"
                >
                  <div class="icon-social-footer-linkedin u-bg-social-icons" />
                </a>
                <a
                  v-if="clubSocial('twitter')"
                  :href="`https://twitter.com/${clubSocial('twitter').link}/`"
                  :title="$t('footer.socialTwitter')"
                  target="_blank"
                  class="twitter"
                >
                  <div class="icon-social-footer-twitter u-bg-social-icons" />
                </a>
                <a
                  v-if="clubSocial('youtube')"
                  :href="`https://www.youtube.com/channel/${clubSocial('youtube').link}`"
                  :title="$t('footer.socialYoutube')"
                  target="_blank"
                  class="youtube"
                  data-test-id="footer-socialYoutube"
                >
                  <div class="icon-social-footer-youtube u-bg-social-icons" />
                </a>
              </div>
            </div>
            <div class="footer-about-description" data-test-id="footer-about-description">
              {{ clubConfig.components.footer.description }}
            </div>
          </div>
        </div>
        <div class="footer-cols">
          <div class="footer-col">
            <p class="copyrights" data-test-id="copyrights">
              {{
                $t('footer.copyrights', {
                  year: new Date().getFullYear(),
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Club from '@/mixins/club-mixin'

export default {
  name: 'ClubFooter',
  mixins: [Club],
  methods: {
    clubSocial(social) {
      return this.clubConfig.components?.socials?.find(x => x.platform === social)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/partials/components/footer.scss';
footer.club {
  background-color: var(--footer-bg);
  border-top: 1px solid var(--bg-grey);
  a {
    text-decoration: none;
    color: var(--footer-color);
    font-weight: 800;
  }
  .footer-logo img {
    max-width: 250px;
  }
  .copyright-tag {
    align-items: center;
    background-color: var(--bg-grey);
    border-radius: $spacing-xs;
    color: var(--dark-blue);
    display: flex;
    font-size: pxToRem(12px);
    gap: $spacing-sm;
    padding: $spacing-sm;
    justify-content: space-between;
    span {
      white-space: nowrap;
    }
    img {
      max-height: 25px;
    }
  }
  .footer-flex-title {
    .title {
      color: var(--footer-color);
    }
    .footer-socials {
      a {
        display: inline-block;
      }
    }
  }
}
</style>
