<template>
  <transition name="fade">
    <div
      v-if="show"
      :style="customTop ? { top: customTop + 'px' } : {}"
      class="snackbar"
      :class="[`u-bg-${color}`, { show: show }]"
      :timeout="timeout"
    >
      <div class="snackbar-message" data-test-id="snackbar-message"><span v-html="message" /></div>
      <div v-if="canBeClosed" class="snackbar-icon icon-close u-bg-white" @click="close" />
    </div>
  </transition>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()

const settledTimeout = ref(null)
const message = computed(() => store.getters['snackbar/message'])
const color = computed(() => store.getters['snackbar/color'])
const timeout = computed(() => store.getters['snackbar/timeout'])
const show = computed(() => store.getters['snackbar/show'])
const canBeClosed = computed(() => store.getters['snackbar/canBeClosed'])
const customTop = computed(() => store.getters['snackbar/customTop'])

watch(
  () => show.value,
  v => {
    if (v) {
      settledTimeout.value = setTimeout(() => {
        close()
      }, timeout.value)
    }
  }
)

onBeforeUnmount(() => {
  if (settledTimeout.value !== null) {
    clearTimeout(settledTimeout.value)
  }
})

const close = () => {
  store.dispatch('snackbar/hide')
  clearTimeout(settledTimeout.value)
}
</script>

<style lang="scss">
.snackbar {
  position: fixed;
  top: 8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;

  display: flex;
  align-items: center;

  padding: $spacing-xs $spacing-xxs $spacing-xs $spacing-sm;
  border-radius: $spacing-xs;

  &-message {
    color: var(--white);
  }

  &-icon:hover {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
</style>
