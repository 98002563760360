<template>
  <div class="searchbar" data-test-id="search-bar">
    <form class="search-form" data-test-id="search-form" @submit.prevent="onSearch">
      <button type="submit" class="search-btn" title="search" />
      <div class="search-input-wrapper" data-test-id="search-input-wrapper">
        <input
          ref="searchbar"
          v-model="keywords"
          type="search"
          class="search-input"
          :placeholder="searchBarPlaceholder"
          @focus="onFocus"
          @blur="onBlur"
          @keyup="onChange"
        />
      </div>
    </form>
    <div v-if="isSuggestionsVisible || isOnlyOverlayVisible" class="search-autocomplete-wrapper">
      <div
        class="search-autocomplete-overlay"
        :class="{ 'club-banner-active': isClub }"
        :style="`--clubBannerHeight: ${clubBannerHeight}px`"
      />
      <div v-if="isLoading" class="loader search-autocomplete-content" />
      <div
        v-else
        class="search-autocomplete-content custom-scrollbar custom-scrollbar-blue"
        data-test-id="home-searchbar-results"
      >
        <div v-if="suggestions?.length > 0" data-test-id="search-suggestions-wrapper">
          <div class="search-suggestions-header" data-test-id="search-suggestions">
            {{ $t('search.suggestions') }}
          </div>
          <div
            v-for="(suggestion, i) in suggestions"
            v-show="suggestion?.name !== ''"
            :key="i"
            :data-test-id="`search-suggestion-${i}`"
            class="search-suggestion"
            @click="searchMode === 'STOCK' ? search(suggestion) : openReference(suggestion)"
          >
            <p v-html="emphasizeKeywords(suggestion.name || suggestion)" />
            <p v-if="suggestion.ref_co" class="search-suggestion-refco">{{ suggestion.ref_co }}</p>
          </div>
        </div>
        <template v-if="categoriesSlug?.length > 0">
          <div data-test-id="search-categories-wrapper">
            <p class="search-suggestions-header" data-test-id="search-categories">
              {{ $t('search.categories') }}
            </p>
            <div
              v-for="(category, i) in categoriesSlug"
              :key="category.slug"
              :data-test-id="`search-category-${i}`"
              class="search-suggestion"
              @click="searchCategory(category)"
            >
              <p v-html="emphasizeKeywords(category.name)" />
            </div>
          </div>
        </template>
        <div v-if="brandsSlug?.length > 0" data-test-id="search-brands-wrapper">
          <div class="search-suggestions-header" data-test-id="search-brands">
            {{ $t('search.brands') }}
          </div>
          <div
            v-for="(brand, i) in brandsSlug"
            :key="brand.slug"
            :data-test-id="`search-brand-${i}`"
            class="search-suggestion"
            @click="searchBrand(brand)"
          >
            <p v-html="emphasizeKeywords(brand.name)" />
          </div>
        </div>
        <div v-if="merchantSlug?.length > 0" data-test-id="search-merchants-wrapper">
          <div class="search-suggestions-header" data-test-id="search-merchants">
            {{ $t('search.merchants') }}
          </div>
          <div
            v-for="(merchant, i) in merchantSlug"
            :key="merchant.slug"
            :data-test-id="`search-merchant-${i}`"
            class="search-suggestion"
            @click="openMerchant(merchant)"
          >
            <p v-html="emphasizeKeywords(merchant.name)" />
          </div>
        </div>
        <div v-if="articlesTitle?.length > 0" data-test-id="search-articles-wrapper">
          <div class="search-suggestions-header" data-test-id="search-articles">
            {{ $t('blog.sectionTitle') }}
          </div>
          <div
            v-for="(article, i) in articlesTitle"
            :key="article.slug"
            :data-test-id="`search-article-${i}`"
            class="search-suggestion"
            @click="openArticle(article)"
          >
            <p v-html="emphasizeKeywords(article.content.title)" />
          </div>
        </div>
        <div v-if="!mkpSuggestions?.length && searchMode === 'STOCK'">
          <div class="search-suggestions-header mkp-suggestions" @click="autocompleteReferences">
            <div class="icon-search u-bg-primary" />
            {{ $t('search.mkpSuggestionsSearch') }}
          </div>
        </div>
        <div v-if="mkpSuggestions?.length > 0 && searchMode === 'STOCK'">
          <div class="search-suggestions-header" data-test-id="search-mkp-suggestions">
            {{ $t('search.mkpSuggestions') }}
          </div>
          <div
            v-for="(suggestion, i) in mkpSuggestions"
            :key="i"
            :data-test-id="`search-mkp-suggestion-${suggestion.name?.toLowerCase()?.replace(/\s/g, '-')}`"
            class="search-suggestion"
            @click="openReference(suggestion)"
          >
            <p v-html="emphasizeKeywords(suggestion.name) || suggestion" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import Fuse from 'fuse.js'

import API from '@/mixins/sp-api-mixin'
import Mobile from '@/mixins/mobile-mixin'
import dynamicContentMx from '@/mixins/dynamic-content-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import { useDynamicContentStore } from '@/stores/dynamicContent'

// import { useCategory } from '@/composables/useCategory'
// import { useBrand } from '@/composables/useBrand'
import { useClubStore } from '@/stores/club'

export default {
  name: 'SearchBar',
  mixins: [Mobile, API, i18nMx],
  data() {
    return {
      timeout: null,
      keywords: null,
      suggestions: [],
      mkpSuggestions: [],
      categoriesSlug: [],
      brandsSlug: [],
      merchantSlug: [],
      suggestionSelected: -1,
      isFocus: false,
      clubBannerHeight: 0,
    }
  },
  computed: {
    ...mapState(useDynamicContentStore, {
      productsCount: store => store.counters.products || 0,
    }),
    isClub() {
      const clubStore = useClubStore()
      return clubStore.isClub
    },
    categories() {
      return this.$store.state.categories.available //.map(category => useCategory(category))
    },
    brands() {
      return this.$store.state.brands.all //.map(brand => (brand ? useBrand(brand) : null)).filter(b => b)
    },
    isSuggestionsVisible() {
      return this.isFocus && this.keywords !== undefined && this.keywords !== null && this.keywords.length >= 3
    },
    isOnlyOverlayVisible() {
      return this.isFocus && this.isMd
    },
    // STOCK or REFERENCE
    searchMode() {
      if (this.$route.name?.includes('stock')) {
        return 'STOCK'
      }
      return 'REFERENCE'
    },
    searchBarPlaceholder() {
      if (!this.isMd) {
        if (this.isClub) {
          return this.$t('fields.searchClub')
        } else {
          return this.searchMode === 'STOCK' ? this.$t('fields.searchStock') : this.$t('fields.search')
        }
      }
      return this.$t('fields.searchName')
    },
    blogArticles() {
      const dynamicContentStore = useDynamicContentStore()
      return dynamicContentStore.getBlogArticles
    },
    articlesTitle() {
      if (this.blogArticles?.length) {
        // thresold = match index (0 = perfect / 1 = match anything)
        const fuseOptions = { keys: ['content.title'], threshold: 0.3, ignoreLocation: true }
        if (this.keywords?.length) {
          const fuse = new Fuse(this.blogArticles, fuseOptions)
          const fuseSearch = fuse.search(this.keywords)
          return fuseSearch
            ?.map(article => {
              return { ...article?.item, name: article?.item?.name?.slice(0, 100) }
            })
            .slice(0, 5)
        }
      }
      return []
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from && !['category-slug', 'search-keywords'].includes(to.name)) {
        this.reset()
      }
    },
  },
  mounted() {
    this.clubBannerHeight = document.querySelector('.club-banner')?.getBoundingClientRect()?.height || 0
  },
  beforeUnmount() {
    this.clubBannerHeight = 0
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    onFocus() {
      this.isFocus = true
      this.isMd ? document.body.classList.add('no-scroll') : ''
      const { analytics, events } = useAnalytics()
      analytics.sendTagEvent(events.TAG_PLAN.SEARCH.CLICK_SEARCH_BAR)
      // get article on focus to preload list
      dynamicContentMx.methods.getDynamicContent({
        $store: this.$store,
        contents: ['BlogArticle'],
        getAll: true, // Get all content to be sure to have all articles
      })
    },
    close() {
      this.isFocus = false
      document.body.classList.remove('no-scroll')
      this.mkpSuggestions = []
    },
    onBlur() {
      const suggestionsDiv = document.querySelector('.search-autocomplete-content:hover')
      if (suggestionsDiv !== null && suggestionsDiv !== undefined) {
        if (!(suggestionsDiv.length !== 0)) {
          this.close()
        }
      } else this.close()
    },
    onSearch() {
      if (this.suggestionSelected < 0) this.search(this.keywords)
    },
    onChange() {
      const { events } = useAnalytics()
      this.isOnlyOverlayVisible || this.isSuggestionsVisible
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')

      this.$bus.emit(events.CLOSE_BURGER_MENU)
      if (this.keywords && this.keywords.length >= 3 && this.isFocus) {
        clearTimeout(this.timeout)
        // Debouncing autocompletion
        this.timeout = setTimeout(() => {
          // if (this.searchMode === 'REFERENCE') {
          //   this.autocomplete()
          // } else {
          //   }
          this.autocomplete()
        }, 400)
      }
    },
    search(text, mkp = false) {
      if (text) {
        const localePath = useLocalePath()
        if (this.searchMode === 'REFERENCE' || mkp) {
          // delete unrecognize char for search from suggestions
          let textQuery = text.name || text
          if (Array.isArray(textQuery) && textQuery.length) textQuery = textQuery.join('')
          this.$router.push(
            localePath({
              name: 'search-keywords',
              params: { keywords: textQuery },
            })
          )
        } else {
          this.$router.push(localePath({ name: 'stock', query: { ...this.$route.query, query: text, page: 1 } }))
        }
        this.reset(text.name || text)
      }
    },
    searchCategory(cat) {
      const localePath = useLocalePath()
      if (this.searchMode === 'REFERENCE') {
        this.$router.push(localePath({ name: 'category-slug', params: { slug: cat.slug } }))
      } else {
        this.$router.push(localePath({ name: 'stock', query: { ...this.$route.query, categories: cat.uid, page: 1 } }))
      }
      this.reset(cat.name)
    },
    searchBrand(brand) {
      const localePath = useLocalePath()
      if (this.searchMode === 'REFERENCE') {
        this.$router.push(localePath({ name: 'brand-brand', params: { brand: brand.slug } }))
      } else {
        this.$router.push(localePath({ name: 'stock', query: { ...this.$route.query, brands: brand.uid, page: 1 } }))
      }
      this.reset(brand.name)
    },
    openMerchant(merchant) {
      const localePath = useLocalePath()
      this.$router.push(localePath({ name: 'merchant-slug', params: { slug: merchant.slug } }))
      this.reset(merchant.name)
    },
    openReference(ref) {
      const localePath = useLocalePath()
      this.$router.push(localePath({ name: 'product-slug', params: { slug: ref.slug } }))
      this.reset(ref.name)
    },
    openArticle(article) {
      const localePath = useLocalePath()
      this.$router.push(localePath({ name: 'blog-article', params: { article: article.slug } }))
      this.reset(article.name)
    },
    reset(keyword = null) {
      this.keywords = keyword
      this.suggestionSelected = -1
      this.isFocus = false
      this.$refs.searchbar?.blur()
      this.close()
    },
    autocompleteReferences() {
      const filters = [
        {
          type: 'KEYWORDS',
          value: this.keywords.trim(),
        },
      ]
      const { $api } = useNuxtApp()
      this.spRequest({
        req: $api().autocomplete.getMarketplace(filters),
      }).then(results => {
        if (results !== null && results !== undefined) {
          this.mkpSuggestions = results?.references?.map(ref => {
            return { ...ref, name: ref.name.slice(0, 100) }
          })
        }
      })
    },
    async autocomplete() {
      const { $api, $currentUser } = useNuxtApp()
      const filters = [
        {
          type: 'KEYWORDS',
          value: this.keywords,
        },
      ]
      let req = $api().autocomplete.getMarketplace(filters)
      if (this.searchMode === 'STOCK') {
        req = $api().autocomplete.getStock($currentUser().companyUid, filters)
      }
      const results = await this.spRequest({ req })
      if (results !== null && results !== undefined) {
        if (this.searchMode === 'STOCK') {
          this.suggestions = results.stocks?.map(stock => stock.reference.name.slice(0, 100))
        } else {
          this.suggestions = results.references?.map(ref => {
            return { ...ref, name: ref.name.slice(0, 100) }
          })
        }
        this.categoriesSlug = [
          ...(results.categories?.map(category => {
            return { ...category, name: category.name.slice(0, 100) }
          }) || []),
        ]
        this.brandsSlug = [
          ...(results.brands?.map(brand => {
            return { ...brand, name: brand.name.slice(0, 100) }
          }) || []),
        ]
        this.merchantSlug = results.companies?.map(merchant => {
          return { ...merchant, name: merchant.name.slice(0, 100) }
        })
      }
    },
    matchingCategories(keywords) {
      const matches = []
      const findMatchingCategories = function (arr, query, nestingKey) {
        arr.forEach(item => {
          const name = encodeURIComponent(item.name?.toLowerCase())
          if (name.includes(query)) {
            matches.push(item)
          }
          if (item[nestingKey]) {
            findMatchingCategories(item[nestingKey], query, nestingKey)
          }
        })
      }
      findMatchingCategories(this.categories, encodeURIComponent(keywords?.toLowerCase()), 'children')
      return matches
    },
    matchingBrands(keywords) {
      const matches = []
      this.brands.forEach(item => {
        const name = encodeURIComponent(item.name?.toLowerCase())
        if (name.includes(encodeURIComponent(keywords?.toLowerCase()))) {
          matches.push(item)
        }
      })
      return matches
    },
    emphasizeKeywords(text) {
      if (text && text.name !== '' && this.keywords) {
        const re = new RegExp(`(?<=${this.keywords})|(?=${this.keywords})`, 'gi')
        return text
          ?.split(re)
          .map(x => (re.test(x) ? `<span class="u-font-black">${x}</span>` : x))
          .join('')
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss">
.searchbar {
  position: relative;

  .search-form {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--bg-grey);
    border-radius: 5px;
    overflow: hidden;
    padding: 5px 0;
    height: 42px;

    .search-input-wrapper {
      position: relative;
      flex: 1 1 calc(100% - 40px);
      padding: 0 10px 0 0;

      .search-input {
        outline: none;
        background-color: transparent;
        border: none;
        color: var(--night-blue);
        font-family: Avenir;
        font-size: pxToRem(15px);
        font-weight: 800;
        width: 100%;
        min-width: 0;

        &::placeholder {
          color: var(--steel-grey);
          font-size: pxToRem(15px);
          font-weight: normal;
        }
      }
    }

    .search-btn {
      outline: none;
      flex: 0 0 40px;
      width: 40px;
      height: 36px;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: transparent;
      -webkit-appearance: none;
      border: none;
      color: var(--primary);
      cursor: pointer;
      mask-image: url('@/assets/icons/ico-search.svg');
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: var(--primary);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .search-autocomplete-wrapper {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    background: var(--white);
    border-radius: 0 0 5px 5px;
    padding: 0;
    overflow: hidden;

    .search-autocomplete-overlay {
      position: fixed;
      // top: $header-height;
      top: 80px;
      height: calc(100vh - 80px);
      left: -$navbar-left-width;
      width: calc(100vw + $navbar-left-width);
      backdrop-filter: blur(2px);
      z-index: 1;

      @include mq($mq-md) {
        // top: $header-height-sm;
        top: 100px;
      }
      @include mq($mq-sm) {
        // top: $header-height-sm;
        top: 163px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--black);
        opacity: 0.4;
      }
      &.club-banner-active {
        top: calc(81px + var(--clubBannerHeight));
        @include mq($mq-sm) {
          top: calc(81px + var(--clubBannerHeight));
        }
      }
    }

    .search-autocomplete-content {
      position: relative;
      z-index: 2;
      background-color: var(--white);
      max-height: calc(100vh - #{$header-height} - 20px);
      overflow: auto;
      padding-top: 5px;

      @include mq($mq-sm) {
        max-height: calc(100vh - #{$header-height-sm} - 20px);
      }

      .search-suggestions-header {
        background-color: var(--background-grey);
        font-weight: 800;
        padding: 10px 10px 10px 37px;

        @include mq($mq-sm) {
          padding: 10px;
        }

        &.mkp-suggestions {
          align-items: center;
          cursor: pointer;
          color: var(--primary);
          display: flex;
          gap: $spacing-sm;
          text-decoration: underline;
        }
      }

      .search-suggestion {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: var(--white);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        line-height: 120%;
        padding: 10px 10px 10px 37px;
        margin: auto 0;

        @include mq($mq-sm) {
          padding: 10px;
        }

        &:hover,
        &.selected {
          background: var(--background-blue);
        }

        &-refco {
          background-color: var(--bg-grey);
          border-radius: $spacing-xs;
          font-size: pxToRem(12px);
          color: var(--blue-dark);
          font-weight: 800;
          height: 20px;
          line-height: 20px;
          padding: 0 $spacing-xs;
        }
      }
    }
  }
}
</style>
