<template>
  <li v-if="totalCompanies > 1" class="company-selector-menu" data-test-id="company-selector-menu">
    <a class="pointer" @click="$emit('update-show-company-list', true)">
      <img
        src="@/assets/icons/ico-building.svg"
        class="header-ico-scale"
        width="19"
        :alt="$t('header.companyChoice')"
        :title="$t('header.companyChoice')"
      />
      <span>{{ $t('header.companyChoice') }}</span>
    </a>
    <nav v-if="showCompanyList" class="submenu is-active company-selector" data-test-id="company-selector">
      <ul>
        <li class="searchbar u-d-f u-ai-c" data-test-id="company-selector-search">
          <form class="search-form" data-test-id="company-selector-search-form">
            <button class="search-btn" data-test-id="company-selector-search-btn" />
            <div class="search-input-wrapper">
              <input v-model="search" :placeholder="$t('fields.searchShort')" type="text" class="search-input" />
            </div>
          </form>
          <div class="icon-close u-bg-night-blue pointer" @click="$emit('update-show-company-list', false)" />
        </li>
        <div v-if="isLoading" class="loader company-selector-loader" />
        <template v-else>
          <li
            v-for="company in companies"
            :key="company.uid"
            :data-test-id="`company-${company.name.toLowerCase().replace(/\s/g, '-')}`"
          >
            <a class="pointer" @click="setCurrentCompany(company.uid)">
              <div
                class="company-selector-badge"
                :data-test-id="`company-badge-${company.name.toLowerCase().replace(/\s/g, '-')}`"
                :class="{ active: isCurrentCompany(company.uid) }"
              >
                {{ company.name[0] }}
              </div>
              <span
                class="company-selector-name"
                :data-test-id="`company-selector-${company.name.toLowerCase().replace(/\s/g, '-')}`"
              >
                {{ company.name }}
              </span>
              <img
                v-if="isCurrentCompany(company.uid)"
                src="@/assets/icons/ico-check-grey.svg"
                alt="ico-check"
                width="30"
              />
            </a>
          </li>
          <li v-if="search && companies.length === 0" class="company-selector-clear">
            {{ $t('message.noResult') }}
          </li>
        </template>
        <Pagination
          v-if="!isLoading"
          :current-page="currentPage"
          :max-page="maxPage"
          :return-event="true"
          @update-page="updatePage"
        />
      </ul>
    </nav>
  </li>
</template>
<script>
import API from '@/mixins/sp-api-mixin'
import Pagination from '@/components/Pagination/Pagination'

export default {
  name: 'CompanySelector',
  components: {
    Pagination,
  },
  mixins: [API],
  props: {
    showCompanyList: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-show-company-list', 'close'],
  data() {
    return {
      search: '',
      companies: [],
      currentPage: 1,
      perPage: 10,
      maxPage: null,
      totalCompanies: null,
    }
  },
  computed: {
    user() {
      const { $currentUser } = useNuxtApp()
      return $currentUser()
    },
    // Commented : we use direct api call now, but can be useful.
    // filteredUserCompanies() {
    //   if (this.search && this.search.length) {
    //     const regExp = new RegExp(this.search.normalize('NFD').replace(/[\u0300-\u036f]/gi, ''), 'gi')
    //     return this.companies.filter(x => regExp.test(x.name.normalize('NFD').replace(/[\u0300-\u036f]/gi, '')))
    //   }
    //   return this.companies
    // },
    companiesLength() {
      return this.companies.length
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getCompanies()
      },
    },
    search: {
      handler() {
        //delay for typing in input
        setTimeout(() => {
          this.getCompanies()
        }, 1000)
      },
    },
  },
  mounted() {
    this.getCompanies(true)
  },
  methods: {
    close() {
      this.$emit('close')
    },

    isCurrentCompany(companyUid) {
      const { $currentUser } = useNuxtApp()
      if (!$currentUser() || !$currentUser().companyUid) return false
      return $currentUser().companyUid === companyUid
    },
    // init params to have totalcompanies available for this user and not updating during search
    getCompanies(init = false) {
      const filter = {
        kind: 'PROFESSIONAL',
        page: this.currentPage,
        items_per_page: this.perPage,
        search: this.search,
      }
      const { $currentUser } = useNuxtApp()
      if ($currentUser() && $currentUser().isAdmin) {
        filter.full = true
      }
      const { $api } = useNuxtApp()
      this.spRequest({
        req: $api().company.getCompanies(filter),
      }).then(data => {
        this.companies = data?.companies
        this.maxPage = data?.page_count
        this.currentPage = data?.page
        if (init) {
          this.totalCompanies = data?.total
        }
      })
    },
    async setCurrentCompany(companyUid) {
      const { $api, $currentUser } = useNuxtApp()
      const localePath = useLocalePath()
      const user = {
        current_company_uid: companyUid,
      }
      this.spRequest({
        req: $api().user.update($currentUser().uid, $currentUser().companyUid, user),
      })
        // .then(async () => {
        .then(async updatedUser => {
          $currentUser().setUser(updatedUser)
          if (this.$route.path !== localePath('index')) {
            await this.$router.push(localePath('index'))
          }
        })
        .finally(() => {
          this.close()
        })
    },
    updatePage(newPage) {
      this.currentPage = newPage
    },
  },
}
</script>

<style scoped lang="scss">
.submenu.company-selector {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .company-selector-loader {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
  }
  .company-selector-clear {
    padding: 10px 20px;
    color: var(--steel-grey);
    font-weight: 500;
  }
  .company-selector-badge {
    padding: 2px 8px;
    background-color: var(--steel-grey);
    color: var(--white);
    margin-right: 15px;
    border-radius: 3px;
    font-weight: 800;
    &.active {
      background-color: var(--primary);
    }
  }
  .company-selector-name {
    flex-grow: 1;
  }
}
.searchbar {
  margin-left: 10px !important;
  .search-form {
    background-color: var(--bg-grey);
    border-radius: 8px;
    width: calc(100% - 10px);
  }
}
.submenu-toggle .submenu ul li a img :not(.company-selector-menu) {
  margin: 0;
}
</style>
